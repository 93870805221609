<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <!-- <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link> &gt;
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">{{ this.$route.params.domain }}</router-link> &gt; -->
                    <router-link :to="{ name: 'account-view-domain-website-list', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Websites</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ website.label }}</h1>
                <p class="text-caption text-center">Website health check</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Certificate</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-list dense v-if="list">
                            <v-list-item v-if="list.length === 0">No certificates yet.</v-list-item>
                            <v-list-item v-for="item in list" v-bind:key="item.id" @click="onClickItem(item)">
                                <v-list-item-content class="text-start">
                                    <v-list-item-title>{{ item.subject_dn_text }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.domains.join(', ') }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="dialogShowCertificateDetail" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">Certificate</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text class="px-5" v-if="certificate">
                                <p>Issuer: {{ certificate.issuer_dn_text }}</p>
                                <p>Serial number: {{ certificate.serial }}</p>
                                <p>Subject: {{ certificate.subject_dn_text }}</p>
                                <p>Validity: {{ certificate.not_before_text }} to {{ certificate.not_after_text }}</p>
                                <p class="mb-0">Domains:</p>
                                <v-list dense>
                                    <v-list-item v-for="(domain, idx) in certificate.domains" :key="idx" class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ domain }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <template v-if="isPermitServiceAdmin">
                                <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="purple" class="white--text" @click="deployCertificate">Deploy certificate</v-btn>
                                </p>
                                </template>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="grey" @click="dialogShowCertificateDetail = false">
                                    <span>Close</span>
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!-- TODO: offer the "request certificate" function to non-admin users too? it shouldn't be an issue when all features are developed -->
                    <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                    <p class="mb-0 pb-0">
                        <v-btn color="purple" class="white--text" @click="requestCertificate">Request certificate</v-btn>
                    </p>
                    </template>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import WebsiteList from '@/components/account-dashboard/WebsiteList.vue';

export default {
    components: {
        // WebsiteList,
    },
    data: () => ({
        account: null,
        website: null,
        websiteAliasList: null,
        error: null,
        list: null,
        dialogShowCertificateDetail: false,
        certificate: null, // for certificate detail dialog
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.website !== null;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadWebsite() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebsite: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsite({ domain: this.$route.params.domain, id: this.$route.params.websiteId });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list) && response.list.length === 1) {
                    this.website = response.list[0];
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load website');
                }
            } catch (err) {
                console.error('failed to load website', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebsite: false });
            }
        },
        async loadWebsiteAliasList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadWebsiteAliasList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchWebsiteAlias({ website_id: this.$route.params.websiteId });
                console.log(`loadWebsiteAliasList: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.websiteAliasList = response.list;
                }
            } catch (err) {
                console.error('failed to load website alias list', err);
                // this.error = true;
            } finally {
                this.$store.commit('loading', { loadWebsiteAliasList: false });
            }
        },
        async editWebsite() {
            try {
                this.error = false;
                this.$store.commit('loading', { editWebsite: true });
                const match = { id: this.$route.params.websiteId };
                const edit = {};
                const response = await this.$client.account(this.$route.params.accountId).domain.editWebsite(match, edit);
                console.log(`editWebsite: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'OK' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit website' });
                }
            } catch (err) {
                console.error('failed to edit website', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit website' });
            } finally {
                this.$store.commit('loading', { editWebsite: false });
            }
        },
        async loadTlsList() {
            try {
                this.$store.commit('loading', { loadTinydnsConfig: true });
                const response = await this.$client.account(this.$route.params.accountId).websiteTlsCertificate.search({ website_id: this.$route.params.websiteId });
                // const response = await this.$client.main().serviceTlsCertificate.search();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadTinydnsConfig failed', err);
            } finally {
                this.$store.commit('loading', { loadTinydnsConfig: false });
            }
        },
        async requestCertificate() {
            try {
                this.$store.commit('loading', { requestCertificate: true });
                const response = await this.$client.account(this.$route.params.accountId).websiteTlsCertificate.create({ website_id: this.$route.params.websiteId });
                if (response?.isCreated) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Obtained TLS certificate for website' });
                    this.loadTlsList();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to obtain TLS certificate for website' });
                }
            } catch (err) {
                console.error('requestCertificate failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to obtain certificate' });
            } finally {
                this.$store.commit('loading', { requestCertificate: false });
            }
        },
        onClickItem(item) {
            this.certificate = item;
            this.dialogShowCertificateDetail = true;
        },
        async deployCertificate() {
            try {
                this.$store.commit('loading', { deployCertificate: true });
                const response = await this.$client.main().serviceTlsCertificate.deploy({ id: this.certificate.id });
                if (response?.status) {
                    console.log(`deployCertificate: status ${response.status}`);
                }
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Deployed certificate' });
            } catch (err) {
                console.error('deployCertificate failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deploy certificate' });
            } finally {
                this.$store.commit('loading', { deployCertificate: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadWebsite();
        this.loadWebsiteAliasList();
        this.loadTlsList();
    },
};
</script>
